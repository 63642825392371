import React from "react"
import styled from "styled-components"
import CoverMockup from "../components/Images/CoverMockup"
import Muffin from "../components/Images/Muffin"
import Emma from "../components/Images/Emma"
import Layout from "../components/layout"
import SEO from "../components/seo"
import StyledBtn from "../components/StyledBtn"

const Section = styled.div`
  background-color: ${props => props.backgroundColor};
  box-shadow: 0px 4px 9px -4px rgba(50, 50, 93, 0.4);
  padding: 0.5rem;
  position: relative;
  ${props => (props.skew ? `transform: skew(0deg, -5deg)` : ``)}
`
const Col = styled.div`
  align-items: center;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  text-align: center;
  transform: skew(0deg, 5deg);
  margin: 0 auto;
  padding: 3.5rem 0.5rem 1.5rem;
  max-width: 768px;
`
const P = styled.p`
  max-width: 750px;
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gattivista`, `gatti`]} />
    <Section backgroundColor={`#ffeb98`} skew={true}>
      <Muffin skew />
      <CoverMockup width={`500px`} skew />
      <Emma skew />
    </Section>
    <Section backgroundColor={`#ec626b`} skew={true}>
      <Col>
        <h3>Una raccolta di fumetti e illustrazioni... solidale!</h3>
        <P>
          Un mini-brossurato di 76 pagine interne, tra spassosi fumetti e
          splendide illustrazioni, intervallate da alcuni interventi sul mondo
          dei nostri amici felini!
        </P>
        <P>
          Il ricavato verrà devoluto ad alcune associazioni Onlus che gestiscono
          gattili e colonie di randagi.
        </P>
      </Col>
      <div
        style={{
          marginRight: `auto`,
          marginLeft: `auto`,
          display: `flex`,
          flexFlow: `row wrap`,
          justifyContent: `center`,
          transform: `skew(0deg, 5deg)`,
          paddingBottom: `2rem`,
        }}
      >
        <StyledBtn to="/scopri">Scopri di più</StyledBtn>
        <StyledBtn to="/acquista">Acquista</StyledBtn>
      </div>
    </Section>
  </Layout>
)

export default IndexPage
